// append urls here, DO NOT remove this line

const URLS = {
  notifications: "/notifications",
  home: "/",
  notFound: "/404",
  affiliate: "/affiliate",
  maintenance: "/maintenance",
  cart: "/cart",
  failedPayment: "/failed-payment",
  successPayment: "/success-payment/:id",
  checkout: "/checkout",
  orderCompleted: "/order-completed",
  successCheckout: (order: any) => `/checkout/success/${order.id}`,
  paymentGate: (url: any) => `/checkout/payment-gate/${url}`,
  successCheckoutRoute: "/checkout/success/:id",
  paymentGateRoute: "/checkout/payment-gate/:id",
  blog: {
    list: "/blog",
    viewRoute: "/blog/:id/:slug",
    view: (post: any) => `/blog/${post.id}/${post.slug}`,
  },
  faq: "/faq",
  auth: {
    login: "/login",
    forgetPassword: "/forget-password",
    resetPassword: "/rest-password",
    register: "/register",
    verifyForgetPassword: "/verify-password",
    registerVerify: "/register/verify",
  },
  brands: "/brands",
  account: {
    wishlist: "/account/wishlist",
    address: "/address-book",
    editProfile: "/account/edit-profile",
    updatePassword: "/update-password",
    orders: "/account/orders",
    viewOrderRoute: "/orders/:id",
    viewOrder: (order: any) => `/orders/${order.id}`,
  },
  shop: {
    list: "/shop",
    search: "/search",
    offers: "/offers",
    viewCategoryRoute: "/categories/:id/:slug",
    viewProductRoute: "/products/:id/:slug",
    viewProduct: (product: any) => `/products/${product?.id}/${product?.slug}`,
    viewBrandRoute: "/brands/:id/:slug",
    viewCategory: category => `/categories/${category?.id}/${category?.slug}`,
    viewBrand: (brand: any) => `/brands/${brand?.id}/${brand?.slug}`,
  },
  contactUs: "/contact-us",
  pages: {
    aboutUs: "/about-us",
    termsConditions: "/terms-conditions",
    privacyPolicy: "/privacy-policy",
    viewRoute: "/pages/:slug/:id",
    view: (page: any) => `/pages/${page.slug}/${page.id}`,
  },
};

export default URLS;
